<template>
    <div class="supplierShipment-list">
        <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
    </div>
</template>
<script>
    let validCode = (rule, value, callback) => {
        if (value == "" || value == undefined) {
            callback();
        } else {
            //支持输入大小写字母，数字，限制1~32位字符
            let reg = /^[A-z0-9]{1,32}$/;
            if (!reg.test(value)) {
                callback(new Error("商品编号格式不对"));
            } else {
                callback();
            }
        }
    };
    let validItemsName = (rule, value, callback) => {
        if (value == "" || value == undefined) {
            callback();
        } else {
            //仅支持输入数字、汉字，大小写字母，限制字符长度1~32个字符
            let reg = /^[a-zA-Z0-9_\u4e00-\u9fa5]{1,32}$/;
            if (!reg.test(value)) {
                callback(new Error("商品名称格式不对或超过32个字符"));
            } else {
                callback();
            }
        }
    };
    export default {
        data() {
            let _this = this;
            return {
                opt: {
                    title: '经销记录',
                    search: [{
                            key: "merchantId",
                            label: "商家名称",
                            type: 'remoteSearchById',
                            opt: {
                                list: [],
                                change(data,cb) {
                                    _this.opt.search.forEach(item => {
                                        if(item.key == 'category') {
                                        cb({
                                            value: "",
                                            key: item.key,
                                        })
                                        item.opt.list = [];
                                        }
                                    })
                                    _this.getCategory(data); 
                                }
                            }
                        }, {
                            key: "category",
                            label: "商品品类",
                            type: "selectCity",
                            opt: {
                                list: [],
                                parent: "merchantId",
                            }
                        },
                        {
                            key: "name",
                            label: "商品名称",
                            maxlength: 32,
                            rules: [{
                                validator: validItemsName,
                                trigger: ['blur']
                            }]
                        },
                        {
                            key: "code",
                            label: "商品编号",
                            maxlength: 32,
                            rules: [{
                                validator: validCode,
                                trigger: ['blur']
                            }]
                        },
                        
                        {
                            key: "inNumber",
                            label: "商家入库单号",
                        },
                    ],
                    columns: [{
                            label: "商品名称",
                            key: "goodsName"
                        },
                        {
                            label: "商品编号",
                            key: "goodsCode"
                        },
                        {
                            label: "商品品类",
                            key: "goodsCategory"
                        },
                        {
                            label: "经销价（元/件）",
                            key: "distributorPrice"
                        },
                        {
                            label: "销售价（元/件）",
                            key: "goodsPrice"
                        },
                        {
                            label: "商家名称",
                            key: "merchantName"
                        }, 
                        {
                            label: "商家入库单号",
                            key: "inNumber"
                        },
                        {
                            label: "经销商品数（件）",
                            key: "inCount"
                        },
                        {
                            label: "出货时间",
                            key: "createDate"
                        },
                        
                    ],
                    buttons:[],
                },
                categoryList: [],
            };
        },
        created() {
            console.log("bePutInStorage created!!");
        },
        activated() {
            console.log("bePutInStorage activated!!");
            this.getMerchantList()
        },
        deactivated() {
            // 离开页面，重置商品品类的list,避免选择了一个商家之后，离开本页面在不刷新浏览器的情况下再次进入本页面，商品品类显示上个商家数据的问题
            this.opt.search.forEach(item => {
                if(item.key == 'category') {
                    item.opt.list = [];
                }
            })
        },
        mounted() {},
        methods: {
            onGet(opt) {
                let dto = {
                    current: opt.skip,
                    pageSize: opt.limit,
                    ...opt.searchForm,
                    inStartDate: opt.searchForm['date'] ? opt.searchForm['date'][0] : null,
                    inEndDate: opt.searchForm['date'] ? opt.searchForm['date'][1] : null,
                };
                this.post("mall-service/v1/warehouse/query/in/list", dto, {
                    isUseResponse: true
                }).then(res => {
                    let obj = {
                        data: {
                            data: [],
                            total: 0,
                        }
                    }
                    if(res.data.code == 0) {
                      res.data.data.data.forEach(item => {
                        item.distributorPrice = item.distributorPrice || '--';
                        item.goodsPrice = item.goodsPrice || '--';
                      })  
                    }
                    obj.data.total = res.data.data.total;
                    obj.data.data = res.data.data.data;
                    
                    opt.cb(obj.data);
                });
            },
            getCategory(data) {
                if(!data) {
                    return
                }
                let dto = {
                    pageNum: 1,
                    pageSize: 9999,
                    merchantId: data
                };
                this.post("mall-service/category/page/query", dto, {
                    isUseResponse: true
                }).then(res => {
                    var array = res.data.list;
                    array.forEach(item => {
                        item.value = item.id;
                        item.label = item.name;
                    })
                    this.categoryList = array;
                    this.opt.search.forEach(item => {
                        if (item.key == "category") {
                            item.opt.list = array;
                        }
                    })

                })
            },
            // 获取商家列表
            getMerchantList() {
                this.post('/mall-service/merchant/v1/page', {
                    pageNumber: 1,
                    pageSize: 9999,
                    roleType: 1
                }).then(res => {
                    var array = res.data;
                    array.forEach(item => {
                        item.value = item.id;
                        item.label = item.name;
                    })
                    console.log(array);
                    this.opt.search.forEach(item => {    
                        if(item.key == 'merchantId') {
                        item.opt.list = array
                        }
                    })
                })
            },
        }
    };
</script>
<style lang="scss" scoped>
    .dialog_bottom {
        margin-top: 23px;
        width: 100%;
        text-align: center;

        .el-button {
        width: 80px !important;
        height: 36px !important;
        padding-top: 10px;
        }
    }

    .up_add_bg {
        width: 95px;
        text-align: center;
        height: 30px;
        line-height: 30px;
        background: #FFFFFF;
        border: 1px solid #CFCFD7;
        border-radius: 6px;
        cursor: pointer;

        .upload-btn {
        opacity: 0;
        }
    }

    .upload_fileName {
        padding-top: 10px;
        padding-right: 5px;
    }
</style>